.article  {
    position: absolute;
    font-family: 'Avenir Next';
    color: #444;
    /* transition: all 0.2s ease-out; */
}
.article section {
    padding-bottom: 60px;
}

.article h2, .article h3, .article p, .article .half, .article .overlay, .article ol, .article h4 ,.article .grid {
    position: relative;
    margin: 0 220px 0 220px;

}

.article h1 {
    font-family: Arial, Helvetica, sans-serif;
    color: #4cb5ae;
    font-size: 6em;
    padding-bottom: 40px;
    margin: 120px 220px 0 220px;
}

.article h2 {
    color: #274044;
    font-size: 1.5em;
    padding-bottom: 40px;
}

.article h3 {
    font-size: 0.8em;
    text-transform: uppercase;
    padding-bottom: 40px;
}

.article h4 {
    font-size: 0.8em;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 20px;
}

.article p {
    padding-bottom: 100px;

}

.article li {
    padding-bottom: 20px;
}

.article p, .article li {
    color: #777;
    font-size: 0.8em;
}

.article img {
    width: 100%;
    transition: none;
}

.half {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.half *, .half p, .half h3 {
    margin: 0;
}

.half img {
    width: 100%;
    height: auto;
}

.article img:hover {
    transform: none;
}


.article .overlay {
    position: relative;
}

.article .overlay img {
    padding-bottom: 120px;
}
.article .overlay .over {
    position: absolute;
    transition: 0.1s ease-in;
    opacity: 0;
}

.article .overlay .over:hover {
    opacity: 1;
}

.article ol {
    list-style-type: decimal;
    list-style-position: outside;
}

.article img.small {
    width: 30%;
    margin: 0 220px 0 220px;
}


.article a {
    margin: 0;
    padding: 0;
    color: #99886B;
}

.article .grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    column-gap: 10%;
    row-gap: 10%;
    margin-bottom: 40px;
}

.article .grid.column2 {
    grid-template-columns: 1fr 1fr;
}

.article .grid.column3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.article .grid.row1 {
    grid-template-rows: 1fr;
}

.article .grid.row2 {
    grid-template-rows: 1fr 1fr;
}

.article .grid .gleft {
    grid-column: 1/2;
}

.article .grid .gright {
    grid-column: 2/3;
    margin: 0;
}

.article br {
    margin-bottom: 20px;
}

.article .grid .maxHeight {
    max-height: 500px;
    width: auto;
}