.centre {
    position: relative;
    bottom: 0;
    width: 100vw;
    padding-bottom: 80px;
    background-color: #FCFCF7;
}

#btt {
    font-family: 'Noteworthy';
}