.page {
    margin-top: 10vh;
    height: 100%;
}


/* Wrapper setup */

.page .wrapper {
    position: relative;
    height: 80vh;
}

.page .wrapper .tile {
    position: absolute;
    background-color: #fff;
    box-shadow: inset 0 2px 4px #aaa;
    border-radius: 30px 0 0 30px;
    transition: all 0.2s ease-in;
    padding: 20px 80px;
    overflow-y: scroll;
}

.page .wrapper .tile.show {
    padding: 20px 0;
    transition-delay: 0.5s;
}

.page .wrapper .tile.show .art {
    visibility: visible;
    transform: none;
    opacity: 1;
    transition-delay: 0.7s;
}
.page .wrapper .tile.hide .art {
    visibility: hidden;
    transform: scaleY(0);
    opacity: 0;
    transition-duration: 0.1s;
}

.page .wrapper button {
    position: absolute;
    bottom: 30%;
    right: 5%;
    z-index: 10;
    transition: all 0.2s ease-out;
    border: 1.5px solid #ccc;
    padding: 2px 6px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.5);
}

.page .wrapper button.top {
    bottom: 10%;
    
}


/* Expand */

.page .wrapper .tile.hide {
    top: 25%;
    height: 50%;
    left: 60%;
    width: 40%;
}

.page .wrapper .tile.show {
    top: 5%;
    height: 90%;
    left: 10%;
    width: 90%;
}


/* Content */

.page .wrapper .contentWrapper {
    position: absolute;
    left: 30%;
    width: 30%;
    height: 100%;
    display: flex;
}

.page .wrapper .contentWrapper img {
    max-height: 60%;
    max-width: 90%;
    margin: auto;
    transition: all 0.2s ease-out;
}

/* Animatable */

.page .wrapper .tile .content {
    transition: all 0.5s ease-in;
    transition-delay: 0s;

}

.page .wrapper .tile .content.up {
    margin-top: -100%;
}

.page .wrapper .tile .content.down {
    margin-top: 30px;
}

.page .wrapper .tile .art {
    transition: visibility 0.5s ease-in, opacity 0.5s ease-in;
}   