#navbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 10px;
    padding: 0px 80px 0px 80px;
    z-index: 11;
  }

#navbar, #bottomline, #highlight {

  background-color: #ffd483;
}

#navbar h1 {
  font-family: "SignPainter";
  width: 50vw;
  float: left;
  font-weight: lighter;
  font-size: 80px;
  padding-top: 15px;
  transition: all 0.2s ease-out;
  
} 

#navbar .about {
  font-size: 120px;
  color: #4cb5ae;
}


#buttons {
  position: fixed;
  margin: 20px 40px 10px 10px;
  right: 0px;
}

#navbar button {
  width: 140px;
  transition: 0.2s;
  vertical-align: top;
  font-family: 'Noteworthy';
}


#navbar .active {
  padding-top: 10px;
  opacity: 1;
}

#highlight {
  position: fixed;
  height: 6px;
  width: 140px;
  margin-top: 10px;
  margin-right: 40px;
  z-index: -1;
  transition: all 0.1s ease-out;
}

#bottomline {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 10px;
  width: 100vw;
}

a {
    text-decoration: none;
}