#about {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 3fr;
    gap: 50px;
    padding: 8vw 20vw 10vw 20vw;
    overflow: none;
}

#about h1 {
    font-family: 'Courier New', Courier, monospace;
    font-weight: normal;
    color: #2E3D3C;
}

#about p, h2 {
    font-weight: normal;
    padding-bottom: 20px;
}

#about h2 {
    font-style: oblique;
    color: #36817C;
}

#about .centred {
    width: 100%;
    text-align: center;
}

#about img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    border: 4px solid #FFD483;
}

#about .full {
    grid-column: 1/4;
}

#about .back {
    grid-column: 2/4;
}

#about .front {
    grid-column: 1/3;
}

#about .bold {
    font-weight: 600;
    color: #3e3e3e;
}

#about .special {
    color: #cc8500;
}

#about a {
    color: #99886B;
}
#about a:hover {
    color: #aaa;
}

#about .line {
    width: 30vw;
    height: 1px;
    background-color: #99886B;
    margin: 30px auto 30px auto;
    
}

#about .icon {
    border: none;
    width: 30px;
    height: auto;
}