* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Avenir Next', Arial, 'Franklin Gothic Medium', 'Arial Narrow', sans-serif;
  color: #3e3e3e;
  font-size: 20px;
  opacity: 1;
  background-color: #FCFCF7;
}

img {
  transition: all 0.2s ease-out;
}

img:hover {
  transform: scale(1.05);
}

a:visited, a:hover, a:focus, a:hover, a:active { text-decoration: none; color:#aaa }

.title p {
  font-family: 'Courier New', Courier, monospace;
}

#intro {
  margin-left: 20vw;
  margin-top: 240px;
  font-size: 1.3em;
  font-family: 'Avenir Next';
}

#intro p {
  text-align: right;
  float: left;
  margin-right: 20px;
  font-family: 'Avenir Next';
  font-weight: normal;
}

#intro ul {
  float: left;
  list-style: none;
  color: #fff;
}

#intro li {
  transition: all 0.4s ease-in;
  opacity: 0;
  margin-top: -10px;
}

#intro li.show {
  opacity: 1;
  margin-top: 0px;
}

#intro li:nth-child(2) { transition-delay: 0.1s; }
#intro li:nth-child(3) { transition-delay: 0.2s; }
#intro li:nth-child(4) { transition-delay: 0.3s; }
#intro li:nth-child(5) { transition-delay: 0.4s; }


.body {
  margin-top: 180px;
}

p, li {

  line-height: 150%;
}

.body ul {
  float: left;
}

button {

  font-family: 'Avenir Next';
  font-size: 20px;
  font-weight: regular;
  color: #555555;
  opacity: 0.5;
  background: none;
  border: none;
}

button:hover {
  opacity: 1;
} 

@keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes fade-out {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

@keyframes fade-in-s {
  0% {
      opacity: 0;

  }
  100% {
      opacity: 1;
  }
}


html {
  scroll-behavior: smooth;
} 

.shadow {
  box-shadow: 0 0 10px #aaa;
}
.rounded {
  border-radius: 20px;
}