.descript {
    min-width: 400px;
}

.descript h2 {
    font-family: 'Noteworthy';
    font-size: 2em;
    color: #99886B;
    margin-bottom: 15px;
}

.descript .subtitle {
    font-family: 'Courier New', Courier, monospace;
    margin-bottom: 20px;
    color: #aaa;
}